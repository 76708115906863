<template>
	<div id="app">
		<router-view name="header" class="b-header" />
		<main style="min-height: 60vh;padding-top:80px">
			<div class="fixed-top" style="top:80px" v-if="!!countdown && !timerClose">
				<div class="w-100 timeout-box position-relative" style="min-height: 55px;background: #fffdf1;">
					<button type="button" aria-label="關" class="position-absolute close px-2 py-1" style="right: 0; top: 10px; margin: 0px; z-index: 1">
						<font style="vertical-align: inherit;" @click="timerClose=!timerClose">×</font>
					</button>
					<div class="container-fluid custom-container-width py-2 h-100">
						<div class="row align-items-center" style="padding-top: 4px;">
						<div class="text-center mx-auto">
							<a class="text-decoration-none" :href="countdown.url">
							<span class="maintext d-inline-flex justify-content-center align-items-center ">
								<span class="title text-center">
								<span>{{countdown.title}}</span>
								</span>
								<!-- <span class="smallcontext text-center d-none d-lg-inline-block">
								<span>{{countdown.smailconext}}</span>
								</span> -->
								<span class="fortext px-1">{{countdown.fortext}} </span>
								<span class="timetext letter-s-2">
									<span :class="{'d-none d-md-inline':diffTime.day==0}">
									<span class="endday text-deepgold">{{isNaN(diffTime.day)? '-':diffTime.day}}</span>
									<span class="d-inline-block px-1">天</span>
									</span>
									<span class="endtime-hhmmss text-deepgold">{{isNaN(diffTime.h)? '--:--:--:' : diffTime.h+':'+diffTime.i+':'+diffTime.s}}</span>
								</span>
							</span>
							<span class="col-12 d-block d-md-inline mt-1 mt-md-0" style="font-size:13px" v-if="countdown.smailconext!=''">
									{{countdown.smailconext}}
							</span>
							</a>
						</div>
						</div>
					</div>
				</div>
			</div>
			<div>
				<router-view name="indexBody" v-if="isRouterAlive" class="b-body" :class="{'isRunArea':!!countdown && !timerClose}" 
				:style="[{'padding-top':!!countdown && !timerClose? '55px':'0px'}]" />
			</div>
			
		</main>
		<div class="reprot-btn fix-right text-center font-weight-bold gotop d-flex flex-column">
			<div class="d-flex flex-column justify-content-end">
				<transition name="fade" mode="out-in">
					<span v-if="openmessage">
						<span class="d-flex justify-content-end align-items-center position-relative">
							<span class="align-self-start position-absolute mt-n1 cur-print" @click="openmessageFunc()" style="font-size: 10px; z-index: 9999; line-height: 1;"><font-awesome-icon class="text-dark" :icon="['fas', 'times']" /></span>
							<a href="https://lin.ee/uRlnmHQ" target="_blank" style="font-size: 24px;" class="rounded-circle btn btn-success border-0 bg-line not-after text-center mb-1 d-flex align-items-center justify-content-center">
								<i class="bi bi-line"></i>
							</a>
						</span>
						<span class="d-flex justify-content-end align-items-center position-relative">
							<button class="rounded-circle btn btn-primary not-after text-center mb-1 p-0" style="line-height: 1.15rem !important;" @click="setiframe(webreport)">考試<br />提醒</button>
						</span>
					</span>
				</transition>
				
				<button class="rounded-circle border-0 bg-warning text-center overflow-hidden mb-2" v-show="openevent" @click="isevent=!isevent" 
				style="line-height: 1.1;width:48px;height:48px;max-width:48px;max-height:48px ">
					<span class="text-nowrap">雙12</span>
					<span class="text-nowrap">免運</span>
				</button>
				<button :class="{ ishidden: openmessage }" class="openmessage rounded-circle btn btn-lightblue text-primary not-after text-center mb-1 p-0 d-flex justify-content-center align-items-center" @click="openmessageFunc()">
					<!-- <transition > -->
					<span class="d-flex justify-content-center align-items-center" style="font-size: 24px;"><font-awesome-icon :icon="['far', 'comment-dots']" /></span>
					<!-- <span class="d-flex justify-content-center align-items-center" style="font-size: 18px;" v-else><font-awesome-icon :icon="['fas', 'times']" /></span> -->
				</button>
			</div>
			<div class="rounded-circle" style="width: 40px; height: 0px; transition: all 0.5s;" :style="scrollshow">
				<button class="rounded-circle btn btn-outline-sevenblack not-after mb-1" style="width: 40px; height: 40px;" @click="windowScrollTop">
					<span class="d-flex flex-column justify-content-center align-items-center" style="font-size: 18px; line-height: 1;">
						<font-awesome-icon :icon="['fas', 'angle-up']" />
					</span>
				</button>
			</div>
		</div>
		<!-- <div class="reprot-btn bg-gold text-center" :style="scrollshow" v-show="!showreport" @click="setiframe(webreport)">
      問題<br>回報
    </div> -->
		<!-- <div v-if="liffInfo.isLiff" style="position: fixed; bottom: 0; width: 100%; background-color: #999; z-index: 999; height: 40px; overflow: auto;">{{ liffInfo }}</div> -->
		<router-view name="footer" class="b-footer" />
		<b-modal id="m-login" size="sm" title="" :header-class="['text-center', 'border-bottom-2', 'position-relative', 'p-0']" body-class="bg-light" v-model="openlogin" centered footer-class="bg-white border-0 ie-footer" @hide="resetLogin" modal-header-close="true">
			<template #modal-header="{close}">
				<div class="w-100 d-flex pt-3 letter-s-2 align-items-stretch nextlink">
					<a href="#" class="loginbox-memberlink cur-default px-1 py-2 w-50 m-0"><b class="text-deepgold">會員登入</b></a>
					<!-- <a class="cur-default border-left align-self-center"
            style="max-width:1px;height:16px">&nbsp;</a> -->
					<a href="https://vclass.magee.tw/" v-if="!isLogin" class="loginbox-onkinelink px-1 py-2 w-50 m-0 h-link d-block underline-blue">前往線上課程</a>
					<a :href="goonlineurl" v-if="isLogin" class="loginbox-onkinelink px-1 py-2 w-50 m-0 h-link d-block underline-blue">前往線上課程</a>
				</div>
				<button type="button" aria-label="關" class="position-absolute close px-2 py-1" style="right: 0%; top: 0%; margin: 0px;" @click="close()">
					<font style="vertical-align: inherit;">×</font>
				</button>
			</template>
			<template #default="{close}">
				<div class="w-100 mx-auto pt-2 pb-1 mt-1" v-if="!isLogin">
					<b-form @submit.prevent="loginbtn()">
						<b-form-group label-cols="2" label-cols-lg="2" label-size="md" label-class="py-0 text-center align-self-center text-black" label="帳號" label-for="input-sm">
							<b-form-input type="email" size="sm" v-model="login.account" class="font-weight-normal font-up-1" style="height: 40px;" placeholder="請輸入電子信箱"> </b-form-input>
						</b-form-group>
						<b-form-group label-cols="2" label-cols-lg="2" label-size="md" label-class="py-0 text-center align-self-center text-black" label="密碼" label-for="input-sm">
							<b-form-input type="password" size="sm" class="font-weight-normal font-up-1" style="height: 40px;" placeholder="請輸入6~12碼字元" v-model="login.password"> </b-form-input>
						</b-form-group>
						<div class="mt-n1 mb-1 text-right offset-2 text-gray small text-secondary">
							<a href="/forgetpw" @click.prevent="goforget(close)" class="text-primary letter-s-0">忘記密碼</a>
							<span class="d-inline-block mx-1">/</span>
							<a href="/gogetmeberopen" @click.prevent="gogetmeberopen(close)" class="text-sevenblack letter-s-0">重寄帳號啟用信</a>
						</div>
						<div class="d-flex justify-content-around">
							<b-button class="btn btn-gold flex-fill font-up-2" size="lg" type="submit">登入</b-button>
						</div>
					</b-form>
					<div class="px-2 align-items-end pt-2 mt-2 font-up-0 text-center" style="line-height: 1.25;">
						<b-link href="#" class="mr-2 text-dark" size="" @click="goregister(close)"><span class="text-deepgold">還沒有帳號？</span>立即免費註冊！</b-link>
					</div>
				</div>
				<div class="w-100 mx-auto" v-if="isLogin">
					<p class="text-black context text-center font-up-4 my-2 letter-s-2">
						<span class="">歡迎，{{ ismember.name }}！</span>
					</p>
				</div>
			</template>
			<template #modal-footer="{close}">
				<div class="row text-center">
					<div class="col-4 text-center py-2">
						<!-- <a href="#" @click.prevent="gomember('chpwd',close)">
              <span class="d-block mb-1" style="height:28px">
                <font-awesome-icon :icon="['fas', 'key']" class='d-block mx-auto text-deepgold'
                  style="font-size:21px; " />
              </span>
              <span class="text-nowrap" style="font-size:14px">密碼修改</span>
            </a> -->
						<a href="/istudy">
							<span class="d-block mb-1" style="height: 28px;">
								<span class="text-nowrap text-deepgold font-weight-bold" style="font-size: 14px;">iStudy</span>
								<!-- <font-awesome-icon :icon="['fas', 'book-open']"
                  class='d-block mx-auto text-deepgold' style="font-size:21px; " /> -->
							</span>
							<span class="text-nowrap" style="font-size: 14px;">讀者專區</span>
						</a>
					</div>
					<div class="col-4 text-center py-2">
						<a :href="isLogin ? goonlineurl : 'https://vclass.magee.tw/'">
							<!-- href="https://exam.magee.tw"> -->
							<span class="d-block mb-1" style="height: 28px;">
								<font-awesome-icon :icon="['fas', 'desktop']" class="d-block mx-auto text-deepgold" style="font-size: 24px;" />
							</span>
							<span class="text-nowrap" style="font-size: 14px;">線上課程</span>
						</a>
					</div>
					<div class="col-4 text-center py-2">
						<a href="/member/info" @click.prevent="gomember('member', close)">
							<span class="d-block mb-1" style="height: 28px;">
								<font-awesome-icon :icon="['far', 'address-card']" class="d-block mx-auto text-deepgold" style="font-size: 25px;" />
							</span>
							<span class="text-nowrap" style="font-size: 14px;">會員資料</span>
						</a>
					</div>
					<div class="col-4 text-center py-2">
						<a href="/member/ordersearch" @click.prevent="gomember('ordersearch', close)">
							<span class="d-block mb-1" style="height: 28px;">
								<font-awesome-icon :icon="['fas', 'clipboard-list']" class="d-block mx-auto text-deepgold" style="font-size: 26px;" />
							</span>
							<span class="text-nowrap" style="font-size: 14px;">訂單查詢</span>
						</a>
					</div>
					<div class="col-4 text-center py-2 newFun">
						<a href="/member/ticket" @click.prevent="gomember('ticket', close)">
							<span class="d-block mb-1" style="height: 28px;">
								<font-awesome-icon :icon="['fas', 'ticket-alt']" class="d-block mx-auto text-deepgold" style="font-size: 25px;" />
							</span>
							<span class="text-nowrap" style="font-size: 14px;">折價券</span>
						</a>
					</div>
					<div class="col-4 text-center py-2 oldFun">
						<a href="/member/application" @click.prevent="gomember('application', close)">
							<span class="d-block mb-1" style="height: 28px;">
								<font-awesome-icon :icon="['far', 'calendar-plus']" class="d-block mx-auto text-deepgold" style="font-size: 25px;" />
							</span>
							<span class="text-nowrap" style="font-size: 14px;">課程請假</span>
						</a>
					</div>
					<div class="col-4 text-center py-2">
						<a href="/member/service" @click.prevent="gomember('service', close)">
							<img :src="'https://www.magee.tw/newErp/data/COM17051000010/WImage/icon_customer.png'" class="d-block mx-auto mb-1 text-deepgold" />
							<span class="text-nowrap" style="font-size: 14px;">客服信箱</span>
						</a>
					</div>
					<div class="col-12 text-center mt-2" v-if="isLogin">
						<button @click="logout('logout', close)" class="btn btn-deepgold px-1 py-0 mx-1">
							登出
						</button>
						<button @click="getrefresh(close)" class="btn btn-danger px-1 py-0 mx-1">
							關閉
						</button>
					</div>
				</div>
			</template>
		</b-modal>
		<b-modal id="m-report" v-model="showreport" size="lg" body-class="vh-75 py-0" footer-class="p-0 border-0" header-class="py-1 border-0 bg-light" title-class="font-weight-bolder h6" no-close-on-backdrop centered>
			<template v-slot:modal-header>
				考試提醒
				<button type="button" aria-label="關" class="close" style="line-height: 17px;" @click="$bvModal.hide('m-report')">
					<font style="vertical-align: inherit;">×</font>
				</button>
			</template>
			<iframe defer async="true" class="ml-n3 ml-lg-n5" ref="webreport" width="110%" height="100%" frameborder="0" marginheight="0" marginwidth="0">載入中…</iframe>
			<template v-slot:modal-footer>
				<b-button variant="light" squared block class="m-0" @click="$bvModal.hide('m-report')">關閉 </b-button>
			</template>
		</b-modal>

		<b-modal id="opennotice" v-model="isevent" class="rounded-0" :header-class="['rounded-0', 'border-bottom-0', 'pt-3', 'pb-1', 'border-top', 'border-t-5', 'border-deepgold']" :body-class="['px-0', 'pt-1']" :footer-class="['rounded-0', 'p-0', 'border-0']" centered>
			<template #modal-header="{close}">
				<div class="position-relative w-100 pt-3 pb-0">
					<h5 class="text-deepgold m-0 text-center h1 letter-s-3">雙12 ‧ 限時免運</h5>
					<button type="button" aria-label="關" class="position-absolute close py-2 px-3" style="right: 0%; top: 3px; line-height: 17px;" @click="close()">
						<font style="vertical-align: inherit;">×</font>
					</button>
				</div>
			</template>
			<template>
				<div class="pb-1">
					<table border="0" width="98%" cellspacing="0" cellpadding="0" align="center">
						<tbody>
							<tr>
								<td style="text-align: center;" valign="top">
									<!-- <div class="text-deepgold" style="font-family: 'Castellar'; font-size: 2.5rem; line-height: 3rem;">FREE<br />SHIPPING</div> -->
									<p style="margin: 7px 0 12px; padding: 5px 0px; font-size: 12pt; line-height: 21pt;" align="center">
										<span>
											即日起至 113/12/12 晚上11:59止
											<br />
											凡於“馬跡官網”購買商品方案含
											<span class="d-block font-weight-bold px-1 text-deepgold">《絕對考上導遊✚領隊有聲書[14版]》</span>
										</span>
										<strong class="d-inline-block py-1" style="color: #595757; font-size: 1.2rem;">
											訂單不限金額享免運費服務
										</strong>
									</p>
								</td>
							</tr>
							<tr>
								<td style="padding-left: 2rem; padding-right: 2rem;font-size: 10pt;color: #727171;" valign="top">
									<div class="position-relative d-flex align-items-center justify-content-center mt-2 mb-3">
										<div class="position-absolute text-deepgold bg-white px-3 letter-s-1" style="z-index: 1;">活動說明</div>
										<hr class="col-12 my-0" style="border-color: #999; margin-top: 11pt;" />
									</div>
									<ol style="line-height: 18pt; margin-bottom: 0; line-height: 20pt; padding-left: 10px;">
										<li style="color: #727171;">
											<span style="font-size: 10pt;"> 免領券！符合資格者，當日結帳即享免運活動</span>
										</li>
										<li style="color: #727171;">
											<span style="font-size: 10pt;"> 本活動免費配送區域限臺灣本島、蘭嶼、綠島、澎湖、金門及馬祖地區。</span>
										</li>
										<li style="color: #727171;">
											<span style="font-size: 10pt;">免運配送方式：</span>
											<ul style="font-size: 10pt; padding-left: 10px; list-type-style: disc;">
												<li>郵局宅配到府</li>
												<li>7-11超商取貨</li>
												<!-- <li>
													<b-button v-b-toggle.collapse-1 variant="outline-sevengray" size="sm" class="py-0 mb-1 px-0" style="color: #727171;">
														現場取貨
														<font-awesome-icon :icon="['fas', 'angle-down']" class="text-secondary mx-1" />
													</b-button>
													<b-collapse id="collapse-1">
														<span class=""
															>中心位址/臺北市大安區復興南路二段268號3樓之一
															<a href="/docs/teacher/23" class="btn btn-sm btn-outline-info py-0">查詢</a>
														</span>
													</b-collapse>
												</li> -->
											</ul>
										</li>
										<li>
											活動期間，本公司將保留變更商品及中止活動的權利。
										</li>
									</ol>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</template>
			<template #modal-footer="{close}">
				<button class="btn btn-outline-deepgold w-100 h-100" @click="close">關閉</button>
			</template>
		</b-modal>
		<!--  v-if="updateExists" -->
		<!-- v-if="updateExists" -->
		<button class="fixed-bottom w-100 text-center bg-info text-white" v-show="updateExists" @click="refreshApp">有最新版！點擊刷新{{ updateExists }}</button>
	</div>
</template>

<script>
	import store from "@/store";
	import VConsole from "vconsole";
	import liff from "@line/liff";
	// import Announcement from '@/components/announcement.vue';
	export default {
		data() {
			return {
				nowtime:'',
				timer: null,
				timerClose: false,
				refreshing: false,
				registration: null,
				isevent: false,
				openevent: false,
				openmessage: false,
				updateExists: false,
				isRouterAlive: true,
				liffID: "",
				debug: false,
				login: {
					account: "",
					password: "",
				},
				scrollshow: {
					opacity: 0,
					visibility: "hidden",
				},
				company: process.env.VUE_APP_COMPANY,
				webreport: "https://docs.google.com/forms/d/e/1FAIpQLSfybLZA-VQ_IlrZxIlE_C3-Xff28rSUKleMjiT3Waq932wVJw/viewform?embedded=true&usp=pp_url&entry.919889937=www.magee.tw",
			};
		},
		mounted() {
			const a1 = store.dispatch("Public/getIndex");
			if(this.timer==null){
				this.timer = setInterval(() => {
					this.nowtime = this.$moment().format("YYYY-MM-DD HH:mm:ss");
				}, 1100);
			}
			this.$once('hook:beforeDestroy', () => { 
				if(this.timer=!null){
					clearInterval(this.timer);         
				}
			})
			window.addEventListener("scroll", this.handleScroll);
			// console.log(this);
			// =================
			let nowtime = this.$moment().format("YYYY-MM-DD HH:mm:ss");
			let starttiem = this.$moment("2024-12-11 23:59:59").format("YYYY-MM-DD HH:mm:ss");
			let endtime = this.$moment("2024-12-12 23:59:59").format("YYYY-MM-DD HH:mm:ss");
			// console.log(nowtime , endtime);
			const es = this.$moment(endtime).diff(nowtime) / 1000;
			const ss = this.$moment(starttiem).diff(nowtime) / 1000;
			// console.log(es ,ss);
			if (ss < 0) {
				this.openevent = true;
				if (es < 0) {
					this.openevent = false;
				}
			} else {
				this.openevent = false;
			}
			// =================
		},
		computed: {
			countdown: {
				get() {
					return store.state.Public.Index.countdown;
				}
			},
			diffTime() {
				if(this.countdown){
					const s = this.$moment(this.countdown.enddate).diff(this.nowtime) / 1000;
					const mm = Math.floor((s % (60 * 60)) / 60);
					const ss = Math.floor(s % 60);
					const hh = Math.floor((s % (60 * 60 * 24)) / (60 * 60));
					return {
					day: Math.floor(s / (60 * 60 * 24)),
					h: hh < 10 ? "0" + hh.toString() : hh,
					i: mm < 10 ? "0" + mm.toString() : mm,
					s: ss < 10 ? "0" + ss.toString() : ss
					}
				}else{
					return null
				}
			},
			goonlineurl() {
				const token = this.memberToken.toString().replace(/\//g, "&2FO").replace("/\\/g", "&2Z7");
				return "https://vclass.magee.tw/auth/" + this.company + "/" + token;
			},
			liffInfo: {
				get() {
					return this.$store.state.member.liff;
				},
			},
			openlogin: {
				get() {
					return this.$store.state.Public.openlogin;
				},
				set(data) {
					this.$store.commit("Public/setLoginpage", data);
				},
			},
			showreport: {
				get() {
					return this.$store.state.Public.showreport;
				},
				set(data) {
					this.$store.commit("Public/setreportpage", data);
				},
			},
			ismember: {
				get() {
					return this.$store.state.Public.Login;
				},
			},
			isLogin: {
				get() {
					return this.$store.state.Public.Login.isLogin;
				},
			},
			memberToken: {
				get() {
					return this.$store.state.Public.Login.api_token;
				},
			},
		},
		watch: {
			"$route.name"(newValue, oldValue) {
				if (newValue) {
					this.scrollshow.opacity = 0;
					this.scrollshow.visibility = "hidden";
					var src = this.webreport + this.$route.path;
					if (this.showreport) {
						this.$root.$on("bv::modal::shown", (bvEvent, modalId) => {
							if (modalId == "m-report" && this.$refs.webreport) {
								this.$refs.webreport.contentWindow.location.replace(src);
							}
						});
						console.log("C");
					}
				}
			},
		},
		methods: {
			setiframe(src) {
				this.showreport = !this.showreport;
				if (this.showreport == true) {
					var src = src + this.$route.path;
					this.$root.$on("bv::modal::shown", (bvEvent, modalId) => {
						if (modalId == "m-report" && this.$refs.webreport) {
							this.$refs.webreport.contentWindow.location.replace(src);
						}
					});
					// this.$refs.webreport.contentWindow.location.replace(src);
				}
			},
			logout(routername, close) {
				//window.location.reload();
				this.$router.push({
					name: "logout",
				});
				close();
			},
			handleScroll() {
				var bodyheight = window.scrollMaxY || document.documentElement.scrollHeight || document.body.scrollHeight;
				var getscreen = window.screen.availHeight;
				var getbody50off = bodyheight > 1000 ? bodyheight - 1000 : bodyheight;
				var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
				if (document.querySelector(".gotop")) {
					if (scrollTop <= 100) {
						this.scrollshow.opacity = 0;
						this.scrollshow.height = "0px";
						this.scrollshow.visibility = "hidden";
					}
				}
				if (this.$route.name == "Cart" || this.$route.name == "ProInfoMore" || this.$route.name == "ProInfoOne") {
					if ((bodyheight - getscreen) * 0.075 - scrollTop <= 0 && scrollTop > 300) {
						this.scrollshow.bottom = this.$route.nam == "Cart" ? "120px" : "80px";
						this.scrollshow.opacity = 1;
						this.scrollshow.height = "40px";
						this.scrollshow.visibility = "visible";
					} else if (getscreen <= 100) {
						this.scrollshow.bottom = this.$route.nam == "Cart" ? "120px" : "80px";
					}
				} else {
					if (scrollTop > 200) {
						this.scrollshow.bottom = "30px";
						this.scrollshow.opacity = 1;
						this.scrollshow.height = "40px";
						this.scrollshow.visibility = "visible";
					}
				}
			},
			windowScrollTop() {
				window.scrollTo(0, 0);
			},
			gomember(routername, close) {
				if (this.isLogin) {
					this.$router.push({
						name: routername,
					});
					close();
				} else {
					this.$store
						.dispatch("Public/showToast", {
							vm: this,
							toaster: "b-toaster-top-center b-toast-top-10",
							method: "danger",
							title: "無法使用",
							text: "請先登入會員！",
							time: 300,
						})
						.then((data) => {
							close();
							this.$router.push({
								name: routername,
							});
						});
				}
			},
			getrefresh(close){
				close();
				//window.location.reload();
			},
			goforget(close) {
				this.$router.push({
					name: "forgetpw",
				});
				close();
			},
			gogetmeberopen(close) {
				this.$router.push({
					name: "getmeberopen",
				});
				close();
			},
			goregister(close) {
				this.$router.push({
					name: "register",
				});
				close();
			},
			loginbtn() {
				// console.log(this.$route.name);
				const LoginInfo = _.cloneDeep(this.login);
				LoginInfo.vm = this;
				this.$store.dispatch("Public/Login", LoginInfo).then((data) => {
					// console.log(data.data.status== "open");
					if (data.data.status == "open") {
						if (this.$store.state.member.loginafterClosePage.indexOf(this.$route.name) > -1) {
							this.openlogin = false;
						}
					}
				});
			},
			resetLogin() {
				this.login = {
					account: "",
					password: "",
				};
				if (this.$store.state.member.loginafterclosePath.indexOf(this.$route.name) > -1) {
					if (!this.isLogin) {
						this.$router.push({
							name: "home",
						});
					}
				}
			},
			reload() {
				this.isRouterAlive = false;
				this.$nextTick(function () {
					this.isRouterAlive = true;
				});
			},
			showRefreshUI(e) {
				console.log(e)
				// Display a button inviting the user to refresh/reload the app due
				// to an app update being available.
				// The new service worker is installed, but not yet active.
				// Store the ServiceWorkerRegistration instance for later use.
				this.scrollshow.opacity = 0;
				this.scrollshow.height = "0px";
				this.scrollshow.visibility = "hidden";
				this.registration = e.detail;
				this.updateExists = true;
				if (this.updateExists) {
					this.refreshApp();
				}
				// this.registration.waiting.postMessage("refresh");
				console.log("refresh");
			},
			refreshApp() {
				// Handle a user tap on the update app button.
				this.updateExists = false;
				// Protect against missing registration.waiting.
				if (!this.registration || !this.registration.waiting) {
					console.log("New.");
					return;
				}
				this.registration.waiting.postMessage("skipWaiting");
			},
			openmessageFunc() {
				this.openmessage = !this.openmessage;
			},
		},
		created() {
			// console.log(liff,this.liffID);
			// console.log(this.$route.query.bot,liff.getContext());

			// const vConsole = new VConsole();
			// or init with options
			// console.log(this.$bvToast);
			window.addEventListener("scroll", function (data) {
				store.commit("Public/setScrollTop", window.scrollY);
			});
			// Listen for swUpdated event and display refresh snackbar as required.
			document.addEventListener("swUpdated", this.showRefreshUI, {
				once: true,
			});
			// Refresh all open app tabs when a new service worker is installed.
			if (navigator.serviceWorker) {
				navigator.serviceWorker.addEventListener("controllerchange", () => {
					if (this.refreshing) return;
					this.refreshing = true;
					window.location.reload();
				});
			}
		},
	};
</script>
<style lang="scss">
	#app {
		height: 100%;
	}

	.fade-enter-active,
	.fade-leave-active {
		transition: all ease-out 0.1s;
	}

	.fade-enter-from,
	.fade-leave-to {
		// left: 200%;
		opacity: 0;
	}

	.fade-enter-to,
	.fade-leave-from {
		// left: 0;
		opacity: 1;
	}
	.openmessage {
		transition: all 0.1s;
		height: 3rem !important;
		transform: scale(1);
		overflow: hidden;
		opacity: 1;
		visibility: visible;
		&.ishidden {
			height: 0rem !important;
			transform: scale(0);
			overflow: hidden;
			opacity: 0;
			visibility: hidden;
		}
	}
</style>
